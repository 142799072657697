<template>
    <div class="notes_container">
        <div class="note__text__comtainer">
            <div v-if="!isEdit" class="span__text__wrapper scroll-bar">
                <span ref="textspan" class="tw-h-full" style="white-space: pre-line" v-html="descText ? descText : 'Not Available'"></span>
            </div>
            <!-- <textarea v-else ref="textarea" contenteditable="true" v-model="descText" placeholder="Add notes" class="edit__textarea" :disabled="!isEdit" :style="{ background: isEdit ? '' : 'white' }"> </textarea> -->
        </div>
        <!-- <div class="notes__actions__container" v-if="!readOnly">
            <div v-if="isEdit" class="save__edit__wrapper">
                <span @click="onSave" style="color: var(--brand-color); white-space: pre-line; cursor: pointer;">Save</span>
                <span @click="onCancel" style="color: var(--color-danger); cursor: pointer;">Cancel</span>
            </div>

            <img v-if="!isEdit" src="@/assets/icons/ico-edit-color.svg" class="tw-h-3 tw-ml-2" style="cursor: pointer;" @click="onEdit" />
        </div> -->
    </div>
</template>

<script>
export default {
    name: "monitoring-note",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: String,
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isEdit: false,
            textAreaHeight: 0,
            descText: "",
        };
    },
    computed: {},
    mounted() {
        this.descText = this.value;
    },
    methods: {
        onEdit() {
            this.isEdit = true;
        },
        onSave() {
            this.isEdit = false;
            this.$emit("change", this.descText);
            this.$emit("save");
        },
        onCancel() {
            this.isEdit = false;
            this.descText = this.value;
        },
    },
};
</script>
<style lang="scss" scoped>
.notes_container {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    .note__text__comtainer {
        font-size: 12px;
        // margin-right: 4px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        .span__text__wrapper {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            overflow-y: auto;
            overflow-x: hidden;
            white-space: nowrap;

            // &::-webkit-scrollbar {
            //     width: 8px;
            //     height: 8px;
            // }

            // &::-webkit-scrollbar-track {
            //     background: transparent;
            // }

            // &::-webkit-scrollbar-thumb {
            //     background: #a8b5c9;
            //     border-radius: 20rem;
            // }
            // &::-webkit-scrollbar-corner {
            //     background: transparent;
            // }

            // &::-webkit-scrollbar-thumb:hover {
            //     background: #9cadc6;
            // }
        }
        .edit__textarea {
            width: 100%;
            height: 100%;
            resize: none;
            border: none;
            outline: none;
            overflow-y: scroll;
            background-color: var(--brand-area-color);

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #a8b5c9;
                border-radius: 20rem;
            }
            &::-webkit-scrollbar-corner {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #9cadc6;
            }
        }
    }
    .notes__actions__container {
        width: 40px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        img {
            height: 20px !important ; // increase icon size
            margin-left: 0;
        }
        .save__edit__wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
</style>
