import Input from "@/components/input";
import Button from "@/components/button";
import Tag from "@/components/tag";
import Checkbox from "@/components/checkbox";
import RadialProgressBar from "vue-radial-progress";
import axios from "@/axios";
import MonitoringNote from "./monitoring-note";
import SetStatus from "@/containers/set-status";
import DatePicker from "vue2-datepicker";
import {mapActions, mapGetters} from "vuex";
import {DateTime} from "luxon";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";

import {getPlatformURL, getPlatformRedirectURL} from "@/utils/functions";

export default {
    name: "neo-manual-monitoring",
    props: {
        heading: {
            type: String,
            default: "",
        },
        isHeader: {
            type: Boolean,
            default: true,
        },
        isFooter: {
            type: Boolean,
            default: false,
        },
        isModal: {
            type: Boolean,
            default: false,
        },
        type: {
            type: Array,
            default: [],
        },
    },
    components: {
        "neo-input": Input,
        "neo-tag": Tag,
        RadialProgressBar,
        "monitoring-note": MonitoringNote,
        "neo-set-status": SetStatus,
        "neo-button": Button,
        "neo-datepicker": DatePicker,
        "neo-checkbox": Checkbox,
        Multiselect,
    },
    data() {
        return {
            filters: {
                search: "",
                status: null,
                qaDone: false,
                analystDone: false,
                review: null,
                startDate: null,
                endDate: null,
            },
            tagColor: {
                "Active Media": {
                    bg: "var(--brand-transparent)",
                    text: "var(--brand-color)",
                },
                "Passive Media": {
                    bg: "var(--brand-transparent)",
                    text: "var(--brand-color)",
                },
                Media: {
                    bg: "var(--brand-transparent)",
                    text: "var(--brand-color)",
                },
                Search: {
                    bg: "var(--brand-transparent)",
                    text: "var(--brand-color)",
                },
                Email: {
                    bg: "var(--brand-transparent)",
                    text: "var(--brand-color)",
                },
                "Adverse Media": {
                    bg: "var(--color-danger-bg)",
                    text: "var(--color-danger)",
                },
                Social: {
                    bg: "var(--brand-transparent)",
                    text: "var(--brand-color)",
                },
            },
            headers: [
                {name: "URL", key: "url"},
                {name: "Type", key: "type"},
                {name: "Last Checked Date", key: "last_checked_date"},
                {name: "Upcoming Date", key: "upcoming_date"},
                {name: "Remaining Days", key: "remaining_days"},
                {name: "Status", key: "done"},
                {name: "Last Checked By", key: "reviewed_by"},
                {name: "Monitoring Duration", key: "monitoring_duration"},
                {name: "Notes", key: "notes"},
            ],
            sortObj: {
                sortKey: null,
                reversed: false,
            },
            // sortKey: null,
            editDuration: false,
            data: [],
            isEdit: false,
            descText: "",
            startDate: null,
            endDate: null,
            statusOptions: ["irrelevant", "potential", "confirmed"],
            monitoringFilteredData: [],
        };
    },
    watch: {
        getMonitoringDateFilter() {
            this.getMonitoringData();
        },
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getAuthDetails", "getPermissions", "getCasePersonalData", "getMonitoringDateFilter"]),

        getData() {
            let filteredData = [];
            for (const [filterKey, filterVal] of Object.entries(this.filters)) {
                if (filterKey === "search") {
                    for (const dataItem of this.data) {
                        for (const headerItem of this.headers) {
                            if (headerItem.key && dataItem[headerItem.key] && dataItem[headerItem.key].toString().toLowerCase().includes(filterVal.toLowerCase())) {
                                const isAbsoluteUrl = (url) => /^[a-z][a-z0-9+.-]*:/.test(url);
                                if (dataItem["platform"] === "website") {
                                    dataItem["monitoring_url"] = isAbsoluteUrl(dataItem["username"]) ? dataItem["username"] : "https://" + dataItem["username"];
                                } else {
                                    dataItem["monitoring_url"] = isAbsoluteUrl(dataItem["url"]) ? dataItem["url"] : "https://" + dataItem["url"];
                                }
                                filteredData.push(dataItem);
                                break;
                            }
                        }
                    }
                }
                if (filterKey === "qaDone" && filterVal) {
                    filteredData = filteredData.filter((item) => item.reviewed_by.includes("QA"));
                }
                if (filterKey === "analystDone" && filterVal) {
                    filteredData = filteredData.filter((item) => item.reviewed_by.includes("Analyst"));
                }
                if (filterKey === "status" && filterVal) {
                    filteredData = filteredData.filter((item) => item.status === filterVal.toUpperCase());
                }
                if (filterKey === "review" && filterVal) {
                    filteredData = filteredData.filter((item) => item.reviewed_by_role);
                }
                // if (filterKey === "startDate" && filterVal) {
                //     filteredData = filteredData.filter((item) => {
                //         if ("created_at" in item) {
                //             return DateTime.fromISO(item.created_at) >= DateTime.fromISO(filterVal);
                //         }
                //     });
                // }
                // if (filterKey === "endDate" && filterVal) {
                //     filteredData = filteredData.filter((item) => {
                //         if ("created_at" in item) {
                //             return DateTime.fromISO(item.created_at) <= DateTime.fromISO(filterVal);
                //         }
                //     });
                // }
            }

            // filteredData = filteredData.filter((item) => {
            //     if ("created_at" in item) {
            //         return Date.parse(item.created_at) >= Date.parse(this.getMonitoringDateFilter.startDate);
            //     }
            // });

            // filteredData = filteredData.filter((item) => {
            //     if ("created_at" in item) {
            //         return Date.parse(item.created_at) <= Date.parse(this.getMonitoringDateFilter.endDate);
            //     }
            // });
            if (this.sortObj.sortKey) {
                filteredData = filteredData.sort((a, b) => {
                    let a_val = a[this.sortObj.sortKey] ? a[this.sortObj.sortKey] : "";
                    let b_val = b[this.sortObj.sortKey] ? b[this.sortObj.sortKey] : "";
                    return a_val.toString().localeCompare(b_val);
                });

                if (this.sortObj.reversed) {
                    filteredData = filteredData.reverse();
                }
            }

            if (this.type.length) {
                filteredData = filteredData.filter((item) => this.type.includes(item.type));
            }
            return filteredData;
        },
        checkCaseStatus() {
            return this.getPermissions.includes("monitoring__monitoring") && this.getCaseStatus && this.getCaseStatus !== "Monitoring" && !this.getReadOnlyMode;
        },
        getCaseStatus() {
            return this.getCasePersonalData && Object.keys(this.getCasePersonalData).length ? this.getCasePersonalData.case_state || "To Do" : "";
        },
    },
    async mounted() {
        // this.$refs.manualContentTable.style.height = `${this.$refs.manualContentContainer.offsetHeight - 276}px`;
        await this.getMonitoringData();
        // this.data = data;
    },
    methods: {
        getPlatformURL,
        getPlatformRedirectURL,
        async onNotesChange(doc) {
            let data = {
                case_id: this.$store.getters.getCaseId,
                media_type: doc.media_type || doc.type,
                doc_id: doc._id,
                notes: doc.notes,
            };

            try {
                await axios.put(`/continuous-monitoring-items`, data);
                this.updateMonitoringTableDoc(doc._id);
            } catch (error) {
                this.$toast.error("Network error");
            }
        },

        async getMonitoringData() {
            try {
                let startDate = Date.parse(this.getMonitoringDateFilter.startDate);
                startDate = DateTime.fromMillis(startDate);
                startDate = startDate.toFormat("yyyy-MM-dd");
                let endDate = Date.parse(this.getMonitoringDateFilter.endDate);
                endDate = DateTime.fromMillis(endDate);
                endDate = endDate.toFormat("yyyy-MM-dd");
                console.log(this.getMonitoringDateFilter.dataType);
                let filterparams = {case_id: this.$store.getters.getCaseId, start_date: startDate, end_date: endDate, acknowledge: this.getMonitoringDateFilter.dataType ? this.getMonitoringDateFilter.dataType : "New"};
                let query = Object.keys(filterparams)
                    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(filterparams[k]))
                    .join("&");
                let response = await axios.get(`/continuous-monitoring-items?` + query);
                //let response = await axios.get(`/continuous-monitoring-items?case_id=${this.$store.getters.getCaseId}`);
                // let responseData = [];

                if (response?.data?.data) {
                    this.data = response.data.data;
                    // let data = response.data.data.map((item) => {
                    //     item["checked_to_send"] = true;
                    //     if (item.type.toLowerCase() === "social") {
                    //         item.url = this.getPlatformURL(item.platform, item.username);
                    //     }
                    //     return item;
                    // });
                    // for (let i = 0; i < data.length; i++) {
                    //     if (data[i].status !== "IRRELEVANT") responseData.push(data[i]);
                    // }
                }

                // return responseData;?
            } catch (error) {
                console.error(error);
            }
        },

        onSort(sortKey) {
            if (this.sortObj.sortKey === sortKey) {
                this.sortObj.reversed = !this.sortObj.reversed;
            } else {
                this.sortObj.sortKey = sortKey;
                this.sortObj.reversed = false;
            }
        },

        async updateMonitoringTableDoc(docId) {
            let newData = await this.getMonitoringData();
            let index = this.data.findIndex((item) => item._id === docId);
            let newDoc = newData.find((item) => item._id === docId);
            Object.assign(this.data[index], newDoc);
        },
        async updateDoc(docToUpdate) {
            let doc = {...docToUpdate, case_id: this.$store.getters.getCaseId};
            try {
                let response = await axios.put(`/continuous-monitoring-items/review`, doc);
            } catch (error) {
                this.$toast.error(`${error}`);
            }
        },

        async onDoneMark(doc) {
            if (this.getReadOnlyMode) {
                return;
            } else {
                await this.updateDoc({
                    id: doc._id,
                    url_type: doc.media_type || doc.type,
                    notes: doc.notes,
                    review_status: "done",
                    reviewed_by: this.$store.getters.getUserAuthDetails.username,
                    reviewed_by_role: this.$store.getters.getUserAuthDetails.userRole === "null" ? "" : this.$store.getters.getUserAuthDetails.userRole || "",
                });
                await this.updateMonitoringTableDoc(doc._id);
            }
        },

        async onStatusChange(status, card) {
            let platform = "";
            let doc = {
                id: card._id,
                case_id: this.$store.getters.getCaseId,
                url_type: card.media_type || card.type,
                status: status,
            };
            if (card.media_type.toLowerCase() === "social" || card.type.toLowerCase() === "social") {
                platform = "socialProfiles";
                doc["platform"] = card.platform;
            } else {
                platform = "media";
            }
            try {
                await axios.put(`/continuous-monitoring-items/status`, doc);
            } catch (error) {
                this.$toast.error("Network Error");
            }
        },

        remainingDaysColor(type, remaining_days) {
            if (remaining_days < 0) {
                return "#e95058";
            }

            switch (type) {
                case "startColor":
                    return "#7a90ff";
                case "stopColor":
                    return "#429321";
                case "innerStrokeColor":
                    return "#e6e6e6";
            }
        },
    },
};
